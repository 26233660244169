export const getExperience = () => {
	return [
		{
			role: "SOFTWARE ENGINEER",
			company: "Tenet Healthcare",
			location: "Dallas, Texas, Unitedstates",
			period: "October 2019 - December 2022",
			details: [
				"Developed and maintained 15 applications using Java, Spring Boot, Node.js, Hibernate, MongoDB, MySQL, and PostgreSQL, consistently delivering high-quality solutions and meeting 95% of project deadlines.",
				"Implemented a distributed messaging system using Apache Kafka for real-time data processing in a logistics application, ensuring efficient communication between components and reducing data processing times by 40%.",
				"Enhanced application security by implementing multi-factor authentication and role-based access control using OAuth2, JWT, Spring Security, and custom encryption, reducing security vulnerabilities by 30%.",
				"Leveraged Amazon Web Services (AWS) to architect and deploy cloud-native solutions, increasing application availability to 99.9% and reducing infrastructure costs by 35%.",
				"Optimized front-end performance by implementing lazy loading and server-side rendering using React and Angular, resulting in a 25% improvement in page load times and a 20% increase in user engagement.",
			],
		},
		{
			role: "FULL STACK DEVELOPER",
			company: "HahuCloud",
			location: "Addis Ababa, Ethiopia",
			period: "November 2017 - September 2019",
			details: [
				"Developed and successfully delivered applications using a diverse technology stack, including Spring, Spring Boot, Node.js, Nest.js, Hibernate, TypeORM, and databases like MongoDB, MySQL, and PostgreSQL. These solutions consistently exceeded client expectations and met project deadlines.",
				"Created a microservices architecture using Java, Spring Cloud, and Apache Kafka for an event-driven system, ensuring scalability and seamless communication between services. Redis was used for caching, and Apache facilitated advanced search capabilities, resulting in a 50% boost in system capacity.",
				"Implemented a robust user authentication and authorization system to enhance security and safeguard user data. This involved the utilization of OAuth2, JWT, Spring Security, and third-party authentication mechanisms, resulting in a 35% reduction in security vulnerabilities.",
				"Utilized Amazon Web Services (AWS) to design and deploy scalable cloud solutions, significantly enhancing application performance and availability by 40%.",
				"Enhanced application performance through a series of optimizations, resulting in a 30% reduction in page load times and a 15% increase in user retention. These optimizations involved the use of various techniques and technologies, including Angular and ReactJS.",
				
			],
		},
		// ...add additional experiences as needed
	];
};
