export const getEducation = () => {
	return [
		{
			degree: "Master's degree, Computer Science",
			institution: "Maharishi International University",
			period: "Jan 2023 - Oct 2025",
		},
		{
			degree: "Bachelor's degree, Engineering",
			institution: "Mekelle University",
			period: "Sep 2014 - Dec 2019",
		},
		// ...add additional education entries as needed
	];
};
