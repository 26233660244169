const INFO = {
	main: {
		title: "Rutha portifolio",
		name: "Rutha B.",
		email: "ruthabelay5@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		// twitter: "https://twitter.com/",
		github: "https://github.com/RuthaBelay/",
		linkedin: "https://www.linkedin.com/in/rutha-belay-9a9b50293/",
		// instagram: "https://instagram.com/",
		// stackoverflow: "https://stackoverflow.com/",
		// facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Rutha Belay",
		description:
			"I am a full-Stack Developer with over five years of experience building scalable web applications using mainly Java stacks. I specialize in designing robust APIs, creating dynamic user interfaces with React and Angular, and ensuring thorough testing. I have extensive experience with the Spring framework, microservices architecture, and AWS environments. I excel with frameworks like Spring Boot and Node.js. I am passionate about optimizing performance and security, I have implemented OAuth2, JWT, and Apache Kafka in previous projects.",
	},

	about: {
		title: "Full-stack software engineer",
		description:
			"I'm an experienced Full-Stack Developer with over five years of expertise in building scalable web applications and robust software systems. I have successfully worked on projects involving API design, dynamic user interfaces with React and Angular, and cloud solutions with AWS. My skills include proficiency in JavaScript, Java, and frameworks like Spring Boot and Node.js, as well as extensive experience with microservices architecture and both SQL and NoSQL databases. I'm particularly interested in optimizing application performance and security, having implemented OAuth2, JWT, and Apache Kafka in previous projects. I am eager to leverage my background and skills to foster innovative solutions in a collaborative and dynamic environment.",
	},

	articles: {
		title: "Discover the ease of hosting your React portfolio on AWS by utilizing key services such as S3, CloudFront, and others, to ensure your website is secure and performs optimally.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Expresso-Churn-Prediction",
			description:
				"Expresso is an African telecommunications services company that provides telecommunication services in two African markets: Mauritania and Senegal. Expresso offers a wide range of products and services to meet the needs of customers." +
				"In this solution, AUC was optimized for early stopping rather than logloss.",
			logo: "https://miro.medium.com/v2/resize:fit:1358/1*RzxZF0mmXAsMLrIzAWYDSg.png",
			linkText: "View Project",
			link: "https://github.com/muluwork-shegaw/Expresso-Churn-Prediction-Challenge-by-AIMS-Ghana",
		},

		{
			title: "Issue Tracker",
			description:
				"Crafted a project-focused issue tracking system akin to GitHub using Spring Boot and React JS, powered by Material UI for an intuitive interface. Swagger ensured API documentation, while development benefited from VS Code and IntelliJ IDEA's ultimate versions. Utilized java, spring, react js, Maven",
			logo: "https://www.megadix.it/img/blog/create-react-app-servlet/banner.png",
			linkText: "View Project",
			link: "https://github.com/OKPLY",
		},

		{
			title: "My personal website",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://pbs.twimg.com/media/ECe4DEkWsAAAzGa.jpg",
			linkText: "View Project",
			link: "https://github.com/muluwork-shegaw/AWS-based-Website",
		},

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
