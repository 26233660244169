import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Article from "../components/articles/article";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/articles.css";

const Articles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "articles");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Articles | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="articles" />
				<div className="content-wrapper">
					<div className="articles-logo-container">
						<div className="articles-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="articles-main-container">
						<div className="articles-container">
							<div className="articles-wrapper">
								<h2>
								Discover the ease of hosting your React portfolio on AWS by utilizing key services such as S3, CloudFront, and others, to ensure your website is secure and performs optimally.
								</h2>
								<div>
									<img
										style={{
											width: "100%",
											height: "auto",
											display: "block",
											margin: "0 auto",
										}}
										src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*I4s_VY1Ju7QuXb07bJXTGA.png"
									/>
								</div>
								<div className="aws-steps-container">
									<h3>
										Step 1: Amazon S3 (Simple Storage
										Service)
									</h3>
									<p>
									Amazon S3 is ideal for hosting a static React portfolio. Here’s how to get started:
									</p>
									<ul>
										<li>
										Create an S3 bucket specifically for your static content, such as HTML, CSS, JavaScript, and images.
										</li>
										<li>
										Enable static website hosting on your S3 bucket.
										</li>
										<li>
										Set appropriate bucket policies to make your content publicly accessible.
										</li>
										<li>
										Use the AWS CLI or SDK for automated deployments.
										</li>
										<li>
										Run npm run build to generate your React application's build directory and upload it to the S3 bucket.
										</li>
									</ul>

									<h3>Step 2: Amazon CloudFront</h3>
									<p>
									Amazon CloudFront improves data delivery speed as a powerful CDN. Follow these steps:
									</p>
									<ul>
										<li>
										Set up a new CloudFront distribution.
										</li>
										<li>
										Choose the S3 bucket from Step 1 as the "Origin Domain Name."
										</li>
										<li>
										Enable "Compress Objects Automatically" to boost performance.
										</li>
										<li>
										Configure caching behavior to optimize content delivery.
										</li>
										<li>
										Set index.html as the "Default Root Object."
										</li>
										<li>
										Enable logging for your CloudFront distribution for monitoring and troubleshooting.
										</li>
										<li>
										Access your website using the URL provided by CloudFront.
										</li>
									</ul>

									<h3>Step 3: AWS Lambda</h3>
									<p>
									AWS Lambda can be used for serverless backend functions if needed, though it's not essential for static websites.
									</p>

									<h3>Step 4: Amazon Route 53</h3>
									<p>
									Amazon Route 53 simplifies DNS management for your website. Here's how:
									</p>
									<ul>
										<li>
										Register a domain to serve as your website’s URL.
										</li>
										<li>
										Create a new "Hosted Zone" and set up a "Record Set."
										</li>
										<li>
										Set "Alias" to yes and link it to the CloudFront distribution from Step 2.
										</li>
										<li>
										Configure health checks to monitor the availability of your website.
										</li>
									</ul>

									<h3>Step 5: AWS Certificate Manager</h3>
									<p>
									Manage SSL/TLS certificates easily with AWS Certificate Manager:
									</p>
									<ul>
										<li>
										Request a new public certificate and enter your domain name.
										</li>
										<li>
										Validate the certificate via DNS or email.
										</li>
										<li>
										Once issued, configure your CloudFront distribution to use this certificate for HTTPS.
										</li>
										<li>
										Set up automatic certificate renewal to maintain security without manual intervention.
										</li>
									</ul>

									<p>
									Customize each service's configuration to match your specific needs. For detailed implementation instructions, consult the AWS documentation or get help from a cloud solutions architect. Enjoy smooth and efficient hosting for your React portfolio!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Articles;
